import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import ReactPlayer from 'react-player';
import Loader from '../Common/Loader';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import Modal from '../Common/Modal';

const Watch = () => {
  const { id } = useParams();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate();
  const { seasonIndex, episodeIndex, quality } = location.state || {};
  const [link, setLink] = useState(null);
  const [loading, setLoading] = useState(true);

  const [modalOpen, setModalOpen] = useState(false);
  const [responseCode, setResponseCode] = useState(null);

  useEffect(() => {
    const getLink = async () => {
      setLoading(true);
      try {
        let response;
        if (seasonIndex !== undefined && episodeIndex !== undefined) {
          response = await axiosPrivate.post(`/watch`, {
            code: id,
            season: seasonIndex,
            episode: episodeIndex,
          });
        } else {
          response = await axiosPrivate.post(`/watch`, {
            code: id,
            quality: quality,
          });
        }
        setLink(response.data.formattedLink);
      } catch (error) {
        setLoading(false);
        setResponseCode(error.response?.status || 'Unknown Error');
        setModalOpen(true);
      } finally {
        setLoading(false);
      }
    };

    getLink();
  }, [id, seasonIndex, episodeIndex, quality, axiosPrivate]);

  const handleContextMenu = (e) => {
    e.preventDefault(); // Prevents the default context menu from appearing
  };

  return (
    <div onContextMenu={handleContextMenu}>
      {loading ? (
        <div className='h-screen flex justify-center items-center'>
          <div className=' w-48'>
            <Loader />
          </div>
        </div>
      ) : (
        <div className='fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black'>
          {link && (
            <ReactPlayer
              url={link}
              playing={true}
              controls
              width='100%'
              height='100%'
              config={{
                file: {
                  attributes: {
                    controlsList: 'nodownload',
                  },
                },
              }}
            />
          )}
          {modalOpen && (
            <Modal
              statusCode={responseCode}
              onClose={() => setModalOpen(false)}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Watch;
