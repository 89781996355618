// Home.js
import React from 'react';
import HomeList from './HomeList';
import HeroBanner from './HeroBanner';


const Home = () => {
  return (
    <div className=' mb-36'>
      <HeroBanner />
      < HomeList />
    </div>
  );
};

export default Home;
