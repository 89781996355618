import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
// import Loader from '../Common/Loader';
// import { DataContext } from ".././context/DataProvider";

function Promotion() {
  // const data = useContext(DataContext);
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (data && data.promotion === 1) {
  //   } else if (data) {
  //     navigate('/');
  //   }
  // }, [data, navigate]);

  // if (!data) {
  //   return <div className='h-screen flex justify-center items-center'>
  //     <div className=' w-48'>
  //       <Loader />
  //     </div>
  //   </div>
  // }

  useEffect(() => {
    navigate('/');
  }, []);

  return (
    <div className="bg-gray-900 text-white min-h-screen flex flex-col items-center justify-center">
      <div className="text-center p-6 max-w-3xl">
        <h1 className="text-4xl md:text-5xl font-bold mb-4">
          Discover the Ratings of Latest Trending Movies!
        </h1>
        <p className="text-lg md:text-xl mb-6">
          Download our app to stay updated with all the hottest movie recommendations and ratings, handpicked just for you.
        </p>
        <a href="https://play.google.com/store/apps/details?id=com.cinematix.mobiletalkies&hl=en_US">
          <button className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full transition duration-300">
            Download Now
          </button>
        </a>
      </div>

      <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <img
          src="https://resize.indiatvnews.com/en/resize/newbucket/1200_-/2023/05/hollywood-films-1685007293.jpg"
          alt="Movie Theater"
          className="w-full h-64 object-cover rounded-lg shadow-lg transform hover:scale-105 transition duration-300"
        />
        <img
          src="https://www.mukhyasamacharnews.com/wp-content/uploads/2023/04/Hollywood_1671723863057.jpg"
          alt="Movie Reel"
          className="w-full h-64 object-cover rounded-lg shadow-lg transform hover:scale-105 transition duration-300"
        />
        <img
          src="https://www.scrolldroll.com/wp-content/uploads/2021/12/Best-Hollywood-Movies-of-2021-featured.jpg"
          alt="Cinema Screen"
          className="w-full h-64 object-cover rounded-lg shadow-lg transform hover:scale-105 transition duration-300"
        />
      </div>

      <div className="text-center mt-10">
        <p className="text-lg md:text-xl">
          Don't miss out on any trending movie! Download the app now and dive into the world of cinema.
        </p>
      </div>
    </div>
  );
}

export default Promotion;
