import React, { useState, useEffect } from 'react';
import names from '../MoviesList/names.json';
import posters from '../MoviesList/posters.json';
import series from '../MoviesList/series.json';
import genres from '../MoviesList/genres.json';
import languages from '../MoviesList/lang.json';
import category from '../MoviesList/catgeory.json';
import { FaPlay } from "react-icons/fa";
import { Link } from 'react-router-dom';


const decodeLanguage = (code) => { 
    let decoded = "";
    if (code.toLowerCase().includes("h")) decoded += "Other • ";
    if (code.toLowerCase().includes("e")) decoded += "English • ";
    if (code.toLowerCase().includes("p")) decoded += "Other • ";
    if (code.toLowerCase().includes("g")) decoded += "Other • ";
    if (code.toLowerCase().includes("m")) decoded += "Other • ";
    if (code.toLowerCase().includes("b")) decoded += "Other • ";
    if (code.toLowerCase().includes("k")) decoded += "Other • ";
    if (code.toLowerCase().includes("t")) decoded += "Other • ";
    if (code.toLowerCase().includes("u")) decoded += "Other  • ";
    if (code.toLowerCase().includes("o")) decoded += "Other • ";
    if (code.toLowerCase().includes("l")) decoded += "Other • ";
    return decoded.slice(0, -3);
};

const decodeGenre = (code) => {
    let decoded = "";
    if (code.toLowerCase().includes("a")) decoded += "Action • ";
    if (code.toLowerCase().includes("h")) decoded += "Horror • ";
    if (code.toLowerCase().includes("d")) decoded += "Adventure • ";
    if (code.toLowerCase().includes("r")) decoded += "Romance • ";
    if (code.toLowerCase().includes("c")) decoded += "Comedy • ";
    if (code.toLowerCase().includes("m")) decoded += "Drama • ";
    if (code.toLowerCase().includes("n")) decoded += "Animation • ";
    if (code.toLowerCase().includes("i")) decoded += "Crime • ";
    if (code.toLowerCase().includes("y")) decoded += "Mystery • ";
    if (code.toLowerCase().includes("f")) decoded += "Fantasy • ";
    if (code.toLowerCase().includes("o")) decoded += "Sci-Fi • ";
    if (code.toLowerCase().includes("t")) decoded += "Thriller • ";
    if (code.toLowerCase().includes("s")) decoded += "History • ";
    if (code.toLowerCase().includes("l")) decoded += "Family • ";
    if (code.toLowerCase().includes("u")) decoded += "Documentary • ";
    if (code.toLowerCase().includes("b")) decoded += "Biography • ";
    if (code.toLowerCase().includes("e")) decoded += "Music • ";
    if (code.toLowerCase().includes("p")) decoded += "Sports • ";
    if (code.toLowerCase().includes("w")) decoded += "Western • ";
    if (code.toLowerCase().includes("g")) decoded += "War • ";
    if (code.toLowerCase().includes("j")) decoded += "News • ";
    if (code.toLowerCase().includes("x")) decoded += "Adult • ";
    return decoded.slice(0, -3);
};

const decodeCategory = (code) => {
    let decoded = "";
    if (code.toLowerCase().includes("h")) decoded += "Hollywood • ";
    if (code.toLowerCase().includes("b")) decoded += "Bollywood • ";
    if (code.toLowerCase().includes("s")) decoded += "South Indian • ";
    if (code.toLowerCase().includes("k")) decoded += "Korean • ";
    if (code.toLowerCase().includes("a")) decoded += "Anime • ";
    return decoded.slice(0, -3);
};



export const mainData = () => {
    const merged = [];
    Object.keys(names).forEach(id => {
        const itemPoster = posters[id] || (series[id] ? series[id].mainPoster : "") || "";
        const seriesItem = series[id] || {};
        const itemGenres = decodeGenre(genres[id] || "");
        const decodedCategory = decodeCategory(category[id] || "");
        if (itemPoster) {
            const decodedLanguage = decodeLanguage(languages[id] || seriesItem.lang || "");
            
            const seasons = [];
            if (seriesItem.seasons) {
                const seasonNames = seriesItem.seasons.seasons_names;
                for (const seasonKey in seasonNames) {
                    const seasonName = seasonNames[seasonKey];
                    const season = seriesItem.seasons[seasonName];
                    if (season && season.episodes_url) {
                        seasons.push({
                            name: seasonName,
                            episodes: season.episodes_url
                        });
                    }
                }
            }

            merged.push({
                id: id,
                name: names[id],
                poster: itemPoster,
                genres: itemGenres,
                language: decodedLanguage,
                category: decodedCategory,
                seasons
            });
        }
    });
    return merged;
};



const MainBanner = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        setData(mainData());
    }, []);

    return (
        <div className='ml-28 mt-28'>
            <div className='grid sm:grid-cols-6 grid-cols-2 sm:gap-4 gap-2 mb-10'>
                {data.map(item => (
                    <div key={item.id}>
                        <div className='relative w-[220px] h-[280px] overflow-hidden transition-transform ease-out duration-500 hover:scale-125 hover:z-10 rounded-md'>
                            <img src={item.poster} alt={item.name} className='w-full transition-transform duration-500 transform hover:scale-105' />
                            <footer className='absolute inset-0 bg-black bg-opacity-60 opacity-0 hover:opacity-100 flex flex-col justify-end text-white p-4 transition-opacity duration-300'>
                                <Link to={`/main-list/${item.id}`} className='mb-2 bg-white text-black text-xs w-full h-9 font-semibold rounded-md duration-200 ease-linear transform hover:scale-102 flex items-center justify-center gap-3'>
                                    <FaPlay className='text-xs' /> Watch Now
                                </Link>
                                <p className="uppercase text-sm font-bold">{item.name}</p>
                                <div className='text-xs font-semibold'>
                                    <span>{item.genres}</span> <span>| </span>
                                    <span>{item.language}</span> 
                                    {/* <span>{item.category}</span>     */}
                                </div>
                            </footer>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default MainBanner;
