import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FaPlay } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { MdOutlineSearch } from 'react-icons/md';
import result from '../Images/no-result-search.svg';
import Languages from '../MoviesList/lang.json';
import Series from '../MoviesList/series.json';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Modal from '../Common/Modal';

const decodedLanguage = (code) => {
  if(code){
    let decoded = '';
    if (code.toLowerCase().includes('h')) decoded += 'Other • ';
    if (code.toLowerCase().includes('e')) decoded += 'English • ';
    if (code.toLowerCase().includes('p')) decoded += 'Other • ';
    if (code.toLowerCase().includes('g')) decoded += 'Other • ';
    if (code.toLowerCase().includes('m')) decoded += 'Other • ';
    if (code.toLowerCase().includes('b')) decoded += 'Other • ';
    if (code.toLowerCase().includes('k')) decoded += 'Other • ';
    if (code.toLowerCase().includes('t')) decoded += 'Other • ';
    if (code.toLowerCase().includes('u')) decoded += 'Other • ';
    if (code.toLowerCase().includes('o')) decoded += 'Other • ';
    if (code.toLowerCase().includes('l')) decoded += 'Other • ';
    return decoded.slice(0, -3);
  }else{
    return '';
  }
};

const Search = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [responseCode, setResponseCode] = useState(null);

  const searchData = async (name) => {
    setLoading(true);
    try {
      const response = await axios.post(
        'https://customizebychoice.com/pikchar_api/web/searchmovie',
        { searchTerm: name }
      );
      if (response.data) {
        const data = Object.keys(response.data).map((key) => {
          const isSeries = Series[key] !== undefined;
          return {
            id: key,
            ...response.data[key],
            language: isSeries
              ? decodedLanguage(Series[key].lang)
              : decodedLanguage(Languages[key]),
          };
        });
        setSearchResults(data);
      } else {
        setSearchResults([]);
      }
    } catch (error) {
      console.log("eee "+error);
      setSearchResults([]);
      setResponseCode(error.response?.status || 'Unknown Error');
      setModalOpen(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    if (debouncedSearchTerm !== '') {
      searchData(debouncedSearchTerm);
    } else {
      setSearchResults([]); // Clear search results when debouncedSearchTerm is empty
    }
  }, [debouncedSearchTerm]);

  const handleSearchTermChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
    if (value === '') {
      setDebouncedSearchTerm(''); // Reset debouncedSearchTerm when search term is empty
    }
  };

  return (
    <div className='md:mt-28 mt-0  flex md:justify-center items-center mr-5 '>
      <div className='flex items-center  max-sm:p-2 w-full   rounded-md fixed top-0 md:py-16 py-10  md:ml-44 z-20 bg-gradient-to-t from-transparent to-black'>
        <div className=' md:ml-8 max-md:m-3 flex gap-4   py-2 pl-8 lg:w-[90%] md:w-[77%] w-full border-2 bg-black border-white rounded-lg focus:outline-none focus:white bg-opacity-55 '>
          <div>
            <MdOutlineSearch className=' lg:text-3xl text-2xl font-bold ' />
          </div>
          <input
            type='text'
            placeholder=' Search Movie, shows...'
            className=' bg-transparent text-white focus:outline-none w-full sm:text-lg text-sm'
            value={searchTerm}
            onChange={handleSearchTermChange}
          />
        </div>
      </div>
      {loading ? (
        <>
        <div className=' md:ml-28  md:mt-10 mt-28 max-[320px]:ml-12 ml-6 max-md:hidden'>
          <div className='md:flex md:flex-wrap md:justify-start md:items-center gap-5  grid sm:grid-cols-3 grid-cols-2  max-[320px]:grid-cols-1'>
            {[...Array(30)].map((_, index) => (
              <div
                key={index}
                className='md:w-[180px] md:h-[230px] sm:w-[150px] sm:h-[200px] w-[150px] h-[200px] bg-gray-700 animate-pulse'>
                <Skeleton width='fit' height='100%'  />
              </div>
            ))}
          </div>
        </div>
         {/* phone  */}
         <div className='mt-20 md:hidden'>
    <div>
      {[...Array(4)].map((_, index) => (
        <div key={index} className='mb-4'>
          <div className='flex flex-row gap-5 items-center justify-start ml-5'>
            <div className='w-[105px] h-[130px] bg-gray-300 rounded-md animate-pulse'></div>
            <footer className='flex flex-col gap-7'>
              <div className='w-32 h-4 bg-gray-300 animate-pulse rounded-md'></div>
              <div className='w-12 h-3 bg-gray-300 animate-pulse rounded-md'></div>
              <div className='w-24 h-7 bg-gray-300 animate-pulse rounded-md'></div>
            </footer>
          </div>
        </div>
      ))}
    </div>
  </div>
        </>
      ) : searchResults.length > 0 ? (
        <>
        <div className=' md:ml-28 md:mt-10 mt-28 max-[320px]:ml-12 ml-6 max-md:hidden '>
          <div className='md:flex md:flex-wrap md:justify-start md:items-center gap-5  grid sm:grid-cols-3 grid-cols-2  max-[320px]:grid-cols-1'>
            {searchResults.map((item) => (
              <div key={item.id} className=' mb-10'>
                <div className='relative md:w-[180px] md:h-[230px] sm:w-[150px] sm:h-[200px] w-[150px] h-[200px] overflow-hidden transition-transform ease-out duration-500 sm:hover:scale-125 hover:z-10 rounded-md'>
                  <Link to={`/main-list/${item.id}`} className=' '>
                    <img
                      src={item.poster}
                      alt={item.name}
                      className='md:w-[180px] md:h-[230px] sm:w-[150px] sm:h-[200px] w-[150px] h-[200px] rounded-lg transition-transform duration-500 transform sm:hover:scale-[1.20]'
                    />
                  </Link>

                  <footer className='absolute max-sm:hidden inset-0 bg-black bg-opacity-60 opacity-0 hover:opacity-100 flex flex-col justify-end text-white p-4 transition-opacity duration-300'>
                    {item?.language && (
                      <span className=' flex-1 text-green-400 font-bold text-[10px]'>
                        {item.language}
                      </span>
                    )}
                    <Link
                      to={`/main-list/${item.id}`}
                      className='mb-2 bg-white text-black text-xs w-full h-8 font-semibold rounded-md duration-200 ease-linear transform hover:scale-[1.020] flex items-center justify-center gap-3'>
                      <FaPlay className='text-[10px]' /> Watch Now
                    </Link>
                    <p className='text-xs font-semibold'>{item.name}</p>
                  </footer>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* for phone  */}
        <div className=' mt-20 md:hidden  '>
          <div className=''>
            {searchResults.map((item) => (
              <div key={item.id} className=' mb-4'>
                <div className='  flex flex-row gap-5 items-center justify-start ml-5'>
                  <Link to={`/main-list/${item.id}`} className=' '>
                    <img
                      src={item.poster}
                      alt={item.name}
                      className='  w-[105px] h-[130px] rounded-md'
                    />
                  </Link>

                  <footer className=' flex flex-col gap-7'>
                  <p className='text-xs font-semibold'>{item.name}</p>
                    {item?.language && (
                      <span className='  text-green-400 font-bold text-[10px]'>
                        {item.language}
                      </span>
                    )}
                    <Link
                      to={`/main-list/${item.id}`}
                      className='mb-2  bg-white text-black text-[10px] w-24 h-7 font-semibold rounded-md duration-200 ease-linear transform hover:scale-[1.020] flex items-center justify-center gap-2'>
                      <FaPlay className='text-[8px]' /> Watch Now
                    </Link>
                    
                  </footer>
                </div>
              </div>
            ))}
          </div>
        </div>
        </>
      ) : (
        <div className=' '>
          
            
         
        {debouncedSearchTerm ? (
          
          <div className=' flex flex-col items-center justify-center h-sceen text-center text-sm font-semibold md:text-lg max-md:ml-6 max-md:mt-24 '>
          <img src={result} alt="" className="mt-20 mb-6 " />
          <p className=' mb-4 '>No results found for "{debouncedSearchTerm}". <br /> 
          <span className=" opacity-70 mb-4 "> Try searching with the correct spelling or request this movie using our Request feature</span></p>
          <Link to={`/request`} className=' md:h-10 h-8 flex items-center bg-white  gap-1 sm:px-4 sm:py-2 py-1 px-2 sm:text-sm text-xs  cursor-pointer text-black font-semibold tracking-widest rounded-md hover:bg-red-700 duration-300 hover:gap-2 hover:translate-x-3"'>Request 
          <svg
  className=" sm:w-5 sm:h-5 w-3 h-3"
  stroke="currentColor"
  stroke-width="1.5"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"
    stroke-linejoin="round"
    stroke-linecap="round"
  ></path>
</svg></Link></div>
        ) : (
          <div className=' my-64 max-md:ml-6 '>
  <div className='flex flex-col items-center justify-center h-sceen text-center text-sm font-semibold md:text-lg  '>
    <p className='  mb-4'>
      Enter correct movie/webseries name as same in Google
    </p>
  </div>
</div>
        )}
      </div>
    )}
    {modalOpen && (
        <Modal statusCode={responseCode} onClose={() => setModalOpen(false)} />
      )}
  </div>
  );
};

export default Search;
