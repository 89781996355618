import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { mainData } from '../MainBannerPage/MainBanner';
import { GrInstagram } from "react-icons/gr";
import { SiGmail } from "react-icons/si";
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { IoIosArrowDown } from 'react-icons/io';
import Modal from '../Common/Modal';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import {
  FaPlay,
  FaDownload,
  FaWindows,
  FaLinux,
  FaApple,
} from 'react-icons/fa';
import { TbDownload } from 'react-icons/tb';
import { IoLogoGooglePlaystore } from 'react-icons/io5';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Loader from '../Common/Loader';

const MainList = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [page, setPage] = useState(null);
  const [myData, setMyData] = useState([]);
  const [selectedSeason, setSelectedSeason] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isSeasonListVisible, setIsSeasonListVisible] = useState(false);
  const [selectedEpisodes, setSelectedEpisodes] = useState('');
  const [selectedQuality, setSelectedQuality] = useState('');
  const [loader, setLoader] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [buttonData, setButtonData] = useState({});
  const scrollRef = useRef(null);

  const axiosPrivate = useAxiosPrivate();
  const [modalOpen, setModalOpen] = useState(false);
  const [responseCode, setResponseCode] = useState(null);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.post(
          'https://customizebychoice.com/pikchar_api/web/getmoviedata',
          { code: id }
        );
        if (response.data && response.data.moviedata) {
          setMyData(response.data.moviedata);
        }
      } catch (error) {
        setResponseCode(error.response?.status || 'Unknown Error');
        setModalOpen(true);
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, [id]);

  useEffect(() => {
    const mergedData = mainData();

    if (mergedData && Array.isArray(mergedData)) {
      const foundPage = mergedData.find((item) => item.id === id);

      if (foundPage) {
        setPage(foundPage);
      }
    }
  }, [id]);

  const handleSeasonChange = (event) => {
    const seasonIndex = parseInt(event.target.getAttribute('data-value'), 10);
    if (isNaN(seasonIndex)) {
      // Clear selection
      setSelectedSeason(null);
    } else {
      setSelectedSeason(seasonIndex);
    }
    setIsSeasonListVisible(false); // Hide season list after selection
  };

  const toggleSeasonList = () => {
    setIsSeasonListVisible((prev) => !prev);
  };

  const handleQualityClick = (quality) => {
    setSelectedQuality(quality);
    setButtonData({ type: 'quality', code: id, quality });
    setShowButtons(true);
    // handleModalAction('quality', id, quality, null, null);
  };

  const getQualityDisplay = (quality) => {
    switch (quality) {
      case 'q720':
        return '720p';
      case 'q480':
        return '480p';
      case 'q1080':
        return '1080p';
      default:
        return '';
    }
  };

  const handleEpisodeClick = (seasonIndex, episodeIndex) => {
    setSelectedEpisodes(episodeIndex);
    setButtonData({ type: 'episode', code: id, seasonIndex, episodeIndex });
    setShowButtons(true);
    // handleModalAction('episode', id, null, seasonIndex, episodeIndex);
  };

  // const handleModalAction = async (
  //   type,
  //   code,
  //   quality,
  //   seasonIndex,
  //   episodeIndex
  // ) => {
  //   if (type === 'quality') {
  //     navigate(`/watch/${code}`, {
  //       state: { quality: quality },
  //     });
  //   } else if (type === 'episode') {
  //     navigate(`/watch/${code}`, {
  //       state: {
  //         seasonIndex: seasonIndex + 1,
  //         episodeIndex: episodeIndex + 1,
  //       },
  //     });
  //   }
  // };
  const handleModalAction = async (action) => {
    setShowButtons(false);

    let response;

    if (action === 'watch') {
      if (buttonData.type === 'quality') {
        navigate(`/watch/${buttonData.code}`, {
          state: { quality: buttonData.quality },
        });
      } else if (buttonData.type === 'episode') {
        navigate(`/watch/${buttonData.code}`, {
          state: {
            seasonIndex: buttonData.seasonIndex+1,
            episodeIndex: buttonData.episodeIndex+1,
          },
        });
      }
    } else if (action === 'download') {
      setLoader(true);
      try {
        if (buttonData.type === 'episode') {
          const { seasonIndex, episodeIndex } = buttonData;
          response = await axiosPrivate.post(
            `/watch`,
            {
              code: id,
              season: seasonIndex+1,
              episode: episodeIndex+1,
            }
          );
        } else if (buttonData.type === 'quality') {
          const { quality } = buttonData;
          response = await axiosPrivate.post(
            `/watch`,
            {
              code: id,
              quality: quality,
            }
          );
        }
        
        if (response && response.data && response.data.formattedLink) {
          const downloadLink = document.createElement('a');
          downloadLink.href = response.data.formattedLink;
          downloadLink.download = myData.name+".mp4"; 
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);

          setLoader(false);
          setResponseCode(222);
          setModalOpen(true);
        } else {

        }
      } catch (error) {
        setResponseCode(error.response?.status || 'Unknown Error');
        setModalOpen(true);
        setLoader(false);
      }
    }
  };

  useEffect(() => {
    if (showButtons && scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [showButtons]);

  if (!page) {
    return (
      <div className='flex justify-center items-center'>404 page not found</div>
    );
  }

  return (
    <div>
      {loader ? (
        <div className='h-screen flex justify-center items-center'>
          <div className=' w-48'>
            <Loader />
          </div>
        </div>
      ) : (
        <div className='text-white'>
          <span className='absolute z-10 bottom-0 right-0 md:p-14 p-1'>
            <div class="group relative">
              <button className=' flex flex-col justify-center items-center text-[9px] gap-1'>
                < AiOutlineQuestionCircle
                  className=" md:text-4xl text-3xl max-sm:text-xl hover:scale-125 duration-200  " />
                Need help?
              </button>
              <span
                className="absolute -top-20 left-[45%] -translate-x-[50%] z-20 origin-left scale-0 px-10 rounded-lg    py-2 md:text-xl text-lg font-semibold shadow-md transition-all duration-300 ease-in-out group-hover:scale-100"
              > <div className=' flex flex-col gap-5'>
                  <a href="https://www.instagram.com/cinematix_usa/" className=" "> <GrInstagram /></a>
                  <a href="mailto:xcinematix@gmail.com" className=""><SiGmail /></a>
                </div>
              </span>
            </div>
          </span>
          <div className='relative flex'>
            {loading ? (
              <div className=' w-full h-screen'>
                <Skeleton
                  width='100%'
                  height='100%'
                  baseColor='black'
                  className='shimmer-placeholder'
                />
              </div>
            ) : (
              <img
                src={myData?.poster}
                alt={myData.name}
                className=' h-screen w-full object-cover object-center blur-md '
              />
            )}
            <div className=' max-sm:hidden absolute inset-0 bg-gradient-to-r from-[#0C0404] to-transparent' />
            <div className='absolute inset-0 bg-gradient-to-t from-black to-transparent' />
          </div>

          <div className='pa top-0 md:mt-20 mt-10 md:ml-44 ml-0 p-5'>
            <div className='flex flex-row lg:gap-20 gap-8 mb-10'>
              <div>
                {loading ? (
                  <div className=' flex flex-row gap-10'>
                    <div className='md:w-[540px] md:h-auto lg:w-[520px] lg:h-[540px] sm:w-[520px] sm:h-[540px] w-[200px] h-[220px] rounded-lg'>
                      <Skeleton width='fit' height='100%' baseColor='#3F3F3F' />
                    </div>
                    <div className=' flex flex-col sm:gap-5 gap-5'>
                      <div className=' md:w-[540px] md:h-12 lg:w-[520px] lg:h-12 sm:w-[520px] sm:h-12 w-[100px] h-6 rounded-lg'>
                        <Skeleton width='fit' height='100%' baseColor='#3F3F3F' />
                      </div>
                      <div className='md:w-[480px] md:h-10 lg:w-[500px] lg:h-10 sm:w-[300px] sm:h-12 w-[80px] h-4 rounded-lg '>
                        <Skeleton width='fit' height='100%' baseColor='#3F3F3F' />
                      </div>
                      <div className='md:w-[440px] md:h-8 lg:w-[480px] lg:h-8 sm:w-[280px] sm:h-8 w-[85px] h-4 rounded-lg '>
                        <Skeleton width='fit' height='100%' baseColor='#3F3F3F' />
                      </div>
                    </div>
                  </div>
                ) : (
                  <img
                    src={myData?.poster}
                    alt=''
                    className='md:w-[540px] md:h-auto lg:w-[520px] lg:h-[540px] sm:w-[520px] sm:h-[540px] w-[220px] h-[240px] rounded-lg '
                  />
                )}
              </div>

              <div className=' flex flex-col w-[60%]'>
                {loading ? (
                  <>
                    <Skeleton height={50} width={'60%'} />
                    <Skeleton height={20} width={'40%'} />
                    <Skeleton height={20} width={'40%'} />
                    <Skeleton height={50} width={'20%'} />
                  </>
                ) : (
                  <>
                    <span className='font-bold tracking-tighter sm:mb-6 mb-5 md:text-5xl sm:text-2xl text-xl '>
                    {/* <span className='text-5xl font-bold tracking-tighter sm:text-6xl md:text-7xl lg:text-8xl '> */}
                      {myData.name}
                    </span>
                    <span className='font-semibold  sm:mb-2 mb-4 md:text-xl sm:text-base text-xs text-yellow-300'>
                      {page.genres}
                    </span>
                    <span className='font-semibold  md:text-xl sm:text-base text-xs md:mb-10 mb-32 text-green-500'>
                      {page.language}
                    </span>
                  </>
                )}
                <div className=' max-sm:hidden'>
                  <div className=' flex flex-col lg:flex-row justify-start items-center gap-5'>
                    {myData.quality && myData.quality.includes('h') && (
                      <div>
                        <button
                          className='font-semibold border-red-700 border-2 sm:w-32 sm:h-14 w-28 h-12 sm:text-lg text-sm rounded-md bg-red-700 hover:border-slate-100 hover:bg-transparent hover:scale-100 duration-200 ease-linear transform'
                          onClick={() => handleQualityClick('q1080')}>
                          1080p
                        </button>
                      </div>
                    )}
                    {myData.quality && myData.quality.includes('m') && (
                      <div>
                        <button
                          className='page-button'
                          onClick={() => handleQualityClick('q720')}>
                          720p
                        </button>
                      </div>
                    )}
                    {myData.quality && myData.quality.includes('l') && (
                      <div>
                        <button
                          className='page-button'
                          onClick={() => handleQualityClick('q480')}>
                          480p
                        </button>
                      </div>
                    )}
                  </div>
                </div>

                {myData.seasons && myData.episodes && (
                  <div className='absolute top-48 w-full flex flex-col items-start max-sm:hidden '>
                    <button
                      onClick={toggleSeasonList}
                      className=' w-44 h-12 bg-white bg-opacity-15 rounded tracking-wide font-semibold flex justify-center items-center gap-4'>
                      {selectedSeason !== null
                        ? `Season ${selectedSeason + 1}`
                        : 'Select Season'}{' '}
                      <IoIosArrowDown />
                    </button>

                    {isSeasonListVisible && (
                      <div className=' top-14 w-44 max-h-80 rounded overflow-y-auto bg-[#1D1D1D] bg-opacity-90 flex flex-col justify-start absolute z-20 cursor-pointer'>
                        <div
                          data-value='clear'
                          onClick={handleSeasonChange}
                          className=' text-white p-3 hover:scale-[1.04] transition-all ease-in-out'>
                          Select Season
                        </div>
                        {Array.from({ length: myData.seasons }).map(
                          (_, index) => (
                            <div
                              key={index}
                              data-value={index}
                              onClick={handleSeasonChange}
                              className=' text-white p-3 hover:scale-[1.04] transition-all ease-in-out'>
                              Season {index + 1}
                            </div>
                          )
                        )}
                      </div>
                    )}
                    {selectedSeason !== null &&
                      myData.episodes &&
                      myData.episodes[selectedSeason] && (
                        <div
                          className={`grid lg:grid-cols-5 sm:grid-cols-2  gap-4 mt-5 relative ${
                            myData.episodes[selectedSeason] > 5
                              ? ' max-h-96 overflow-x-auto'
                              : ''
                          }`}>
                          {Array.from({
                            length: myData.episodes[selectedSeason],
                          }).map((_, index) => (
                            <button
                              key={index}
                              className='flex justify-center items-center mb-2 font-semibold text-sm border bg-[#1D1D1D] bg-opacity-100 border-gray-300 w-28 h-12 rounded-md hover:border-red-500 hover:bg-red-500 hover:scale-105 duration-200 ease-linear transform'
                              onClick={() =>
                                handleEpisodeClick(selectedSeason, index)
                              }>
                              Episode {index + 1}
                            </button>
                          ))}
                        </div>
                      )}
                  </div>
                )}
              </div>
            </div>
            {/* For phone */}

            <div className=' flex flex-col lg:flex-row justify-start items-center gap-5 sm:hidden mt-10'>
              {myData.quality && myData.quality.includes('h') && (
                <div>
                  <button
                    className='font-semibold border-red-700 border-2 sm:w-32 sm:h-14 w-28 h-12 sm:text-lg text-sm rounded-md bg-red-700 hover:border-slate-100 hover:bg-transparent hover:scale-100 duration-200 ease-linear transform'
                    onClick={() => handleQualityClick('q1080')}>
                    1080p
                  </button>
                </div>
              )}
              {myData.quality && myData.quality.includes('m') && (
                <div>
                  <button
                    className='page-button'
                    onClick={() => handleQualityClick('q720')}>
                    720p
                  </button>
                </div>
              )}
              {myData.quality && myData.quality.includes('l') && (
                <div>
                  <button
                    className='page-button'
                    onClick={() => handleQualityClick('q480')}>
                    480p
                  </button>
                </div>
              )}
            </div>

            {myData.seasons && myData.episodes && (
              <div className=' flex flex-col justify-center ml-0  items-center sm:hidden mb-20 '>
                <button
                  onClick={toggleSeasonList}
                  className=' w-40 h-10 text-sm mb-2  bg-white bg-opacity-15 rounded tracking-wide font-semibold flex justify-center items-center gap-5 '>
                  {/* Select Season <IoIosArrowDown /> */}
                  {selectedSeason !== null
                    ? `Season ${selectedSeason + 1}`
                    : 'Select Season'}{' '}
                  <IoIosArrowDown />
                </button>

                {isSeasonListVisible && (
                  <div className='max-[375px]:top-[380px] top-[360px] w-40 max-h-80  rounded overflow-y-auto bg-[#1D1D1D] bg-opacity-90 flex flex-col justify-start absolute z-20 '>
                    <div
                      data-value='clear'
                      onClick={handleSeasonChange}
                      className='   text-white p-3 hover:scale-105 duration-200 ease-linear hover:bg-[#333333] transform hover:underline font-semibold block w-44 h-12 bg-[#1D1D1D] bg-opacity-30 cursor-pointer text-base text-left'>
                      Select Season
                    </div>
                    {myData.episodes.map((_, index) => (
                      <div
                        key={index}
                        data-value={index}
                        onClick={handleSeasonChange}
                        className='text-white p-3 hover:scale-105 duration-200 ease-linear transform hover:bg-[#333333] hover:underline font-semibold block w-44 h-12 bg-[#1D1D1D] bg-opacity-30 cursor-pointer text-base text-left'>
                        Season {index + 1}
                      </div>
                    ))}
                  </div>
                )}

                {selectedSeason !== null && (
                  <div className="relative">
                    <div
                      className={`grid lg:grid-cols-5 sm:grid-cols-3 grid-cols-2 gap-4 mt-2 relative ${myData.episodes[selectedSeason] > 12
                          ? ' max-h-96 max-[375px]:h-72 overflow-y-auto '
                          : ''
                        }`}>
                      {Array.from({
                        length: myData.episodes[selectedSeason],
                      }).map((_, episodeIndex) => (
                        <div
                          key={episodeIndex}
                          onClick={() =>
                            handleEpisodeClick(selectedSeason, episodeIndex)
                          }
                          className='flex justify-center items-center mb-2 font-semibold text-sm border bg-[#1D1D1D] bg-opacity-100 border-gray-300 w-28 h-12 rounded-md hover:border-red-500 hover:bg-red-500 hover:scale-105 duration-200 ease-linear transform'>
                          <button>
                            <span>Episode {episodeIndex + 1}</span>
                          </button>
                        </div>
                      ))}
                    </div>
                    {myData.episodes[selectedSeason] > 12 && (
                      <>
                        {/* <div className="absolute top-0 left-0 right-0 h-8 bg-gradient-to-b from-black to-transparent pointer-events-none"></div> */}
                        <div className="absolute bottom-0 left-0 right-0 h-20 bg-gradient-to-t from-black to-transparent pointer-events-none"></div>
                      </>
                    )}
                  </div>
                )}

              </div>
            )}
          </div>

          {showButtons && (
            <div ref={scrollRef} className=' md:ml-28  mb-0 '>
            <div className=' flex justify-center items-center ms-10 me-10 mb-10 font-semibold sm:text-2xl  mt-10 text-center'>
              <span className=' flex flex-col justify-center items-center'>
                {myData.name}  {getQualityDisplay(selectedQuality)}
                {myData?.seasons && (
                  <span>
                    Season {selectedSeason + 1} Episode {selectedEpisodes + 1}
                  </span>
                )}{' '}
              </span>
            </div>

            <div
              className=' flex justify-center flex-row  items-center sm:gap-20 gap-10   '>
              <button
                className=' font-semibold  border-2 sm:w-44 px-2 h-12  text-sm rounded-md border-white bg-white  text-black hover:bg-transparent hover:text-white hover:scale-105 duration-200 ease-linear transform flex items-center justify-center gap-3'
                onClick={() => handleModalAction('watch')}>
                <FaPlay className='text-sm' /> Watch Now
              </button>
              <button
                className=' font-semibold  border-2 sm:w-44 px-2 h-12  text-sm rounded-md border-red-600 bg-red-600  text-white hover:bg-transparent hover:text-white hover:border-white hover:scale-105 duration-200 ease-linear transform flex items-center justify-center gap-3'
                onClick={() => handleModalAction('download')}>
                <FaDownload className='text-sm' /> Download
              </button>
            </div>

            <div className='flex justify-center items-center mb-0 px-2'>
              <div className=' text-white sm:px-6 py-6 px- rounded-lg shadow-md'>
                <table className=' divide-y divide-gray-700'>
                  <thead>
                    <tr>
                      <th className='sm:px-6 px-2 py-3 text-left sm:text-xs text-[10px] font-medium text-gray-400 uppercase tracking-wider'>
                        Notice
                      </th>
                      <th className='sm:px-6 px-2 py-3 text-left sm:text-xs text-[10px] font-medium text-gray-400 uppercase tracking-wider'>
                        Watch Now
                      </th>
                      <th className='sm:px-6 py-3 text-left sm:text-xs text-[10px] font-medium text-gray-400 uppercase tracking-wider'>
                        Download
                      </th>
                    </tr>
                  </thead>
                  <tbody className='divide-y divide-gray-700'>
                    <tr>
                      <td className='sm:px-6 px-2 py-4 sm:text-base text-sm '>
                        Change Subtitles
                      </td>
                      <td className=' '>
                        <span className='text-red-500 flex items-center justify-center w-full '>
                          ✗
                        </span>
                      </td>
                      <td className=' '>
                        <span className='text-green-500 flex items-center justify-center w-full'>
                          ✓
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className='sm:px-6 px-2 py-4 sm:text-base text-sm '>
                        Change Language
                      </td>
                      <td className=''>
                        <span className='text-red-500 flex items-center justify-center w-full'>
                          ✗
                        </span>
                      </td>
                      <td className=' '>
                        <span className='text-green-500 flex items-center justify-center w-full'>
                          ✓
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className='sm:px-6 px-2 py-4 sm:text-base text-sm '>
                        All Audio Supported
                      </td>
                      <td className=' '>
                        <span className='text-red-500 flex items-center justify-center w-full'>
                          ✗
                        </span>
                      </td>
                      <td className=' '>
                        <span className='text-green-500 flex items-center justify-center w-full'>
                          ✓
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className='sm:px-6 px-2 py-4 sm:text-base text-sm '>
                        All Video Supported
                      </td>
                      <td className=' '>
                        <span className='text-red-500 flex items-center justify-center w-full'>
                          ✗
                        </span>
                      </td>
                      <td className=' '>
                        <span className='text-green-500 flex items-center justify-center w-full'>
                          ✓
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>

              </div>
            </div>

              {/* <div className=" text-white mb-10 text-center">
       <div className="flex justify-center space-x-4  mb-8">
         <div>
         <a href={`https://apps.apple.com/us/app/vlc-media-player/id650377962`} target='#' className="page-button w-36 text-sm text-white font-bold py-2 px-4 rounded inline-flex items-center mb-2">
           <FaApple  className="mr-2 text-2xl" />
           App Store
         </a>
         <p className=' font-semibold text-xs'>Vlc for IOS</p>
         </div>
         <div>
         <a href={`https://play.google.com/store/search?q=vlc&c=apps&hl=en-IN`} target='#' className="page-button w-[150px] text-sm text-white font-bold py-2 px-4 rounded inline-flex items-center mb-2">
           <IoLogoGooglePlaystore className="mr-2 text-2xl" />
           Google Play 
         </a>
         <p className=' font-semibold text-xs'>Vlc for Android</p>
         </div>
       </div>
       <div className="flex justify-center space-x-4 ">
         <div>
         <a href={`https://www.smplayer.info/en/downloads`} target='#' className="page-button w-36 text-sm text-white font-bold py-2 px-4 rounded inline-flex items-center mb-2">
           <FaApple  className="mr-2 text-2xl" />
           macOS
         </a>
         <p className=' font-semibold text-xs'>SM player for MAC</p>
         </div>
         <div>
         <a href={`https://www.smplayer.info/en/downloads`} target='#' className="page-button w-36 text-sm text-white font-bold py-2 px-4 rounded inline-flex items-center mb-2">
           <FaWindows className="mr-2 text-2xl" />
           Windows
         </a>
         <p className=' font-semibold text-xs'>SM player for Windows</p>
         </div>
         <div>
         <a href={`https://www.smplayer.info/en/downloads`} target='#' className="page-button w-36 text-sm text-white font-bold py-2 px-4 rounded inline-flex items-center mb-2">
           <FaLinux className="mr-2 text-2xl" />
           Linux
         </a>
         <p className=' font-semibold text-xs'>SM player for Linux</p>
         </div>
       </div>
       
     </div> */}
              <div className=' '>
                <p className=' text-center ms-4 me-4 text-sm mb-4 font-semibold'>
                  Play downloaded movies & series using below players
                </p>
                <div className=' text-center items-center flex flex-col mb-6 '>
                  <a
                    href={`https://www.smplayer.info/en/downloads`}
                    target='#'
                    className='page-button w-36 h-12 text-sm text-white font-bold py-2 px-4 rounded inline-flex items-center mb-2'>
                    {/* <img src={`https://upload.wikimedia.org/wikipedia/commons/4/40/SMPlayer_icon.png`} alt="" className=" w-9  " /> */}
                    <TbDownload className='mr-2 text-2xl' /> Download
                  </a>
                  <p className=' font-semibold text-xs text-cemtre flex flex-row gap-1 items-center justify-center'>
                    Download SM video player for desktop
                    <span className=' flex flex-row gap-2 text-sm'>
                      {' '}
                      <FaApple /> <FaWindows /> <FaLinux />{' '}
                    </span>{' '}
                  </p>
                </div>
                <div className='flex justify-center sm:flex-row flex-col gap-5  mb-8 text-center'>
                  <div>
                    <a
                      href={`https://apps.apple.com/us/app/vlc-media-player/id650377962`}
                      target='#'
                      className='page-button w-36 h-12 text-sm text-white font-bold py-2 px-4 rounded inline-flex items-center mb-2'>
                      <FaApple className='mr-2 text-2xl' />
                      App Store
                    </a>
                    <p className=' font-semibold text-xs'>VLC video player for IOS</p>
                  </div>
                  <div>
                    <a
                      href={`https://play.google.com/store/apps/details?id=com.cinematix.mobiletalkies&hl=en_US`}
                      target='#'
                      className='page-button w-[150px] h-12 text-sm font-bold py-2 px-4 rounded inline-flex items-center mb-2 text-black bg-white md:bg-transparent md:text-white'
                    >
                      <IoLogoGooglePlaystore className='mr-2 text-2xl' />
                      Play Store
                    </a>

                    <p className=' mb-10 md:mb-0 font-semibold text-xs'>Cinematix for Android</p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {modalOpen && (
            <Modal statusCode={responseCode} onClose={() => setModalOpen(false)} />
          )}
        </div>
      )}
    </div>
  );
};

export default MainList;
