import React from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { FaPlay } from "react-icons/fa";
import { mergeDatasets } from './HomeList'; 
import title from '../MoviesList/titltes.json';
import name from '../MoviesList/names.json';
import poster from '../MoviesList/posters.json';
import series from '../MoviesList/series.json';
import genres from '../MoviesList/genres.json';
import languages from '../MoviesList/lang.json';

const HomeListCategory = () => {
  const { category } = useParams();
  const mergedData = mergeDatasets(title, name, poster, series, genres, languages);
  const categoryData = mergedData[category] || [];

  return (
    <div className='md:ml-28 ml-4 mt-10 mb-36 '>
        <h1 className='sm:text-3xl text-xl flex justify-center font-bold mb-8'>{category}</h1>
      <div className=' md:flex md:flex-wrap md:justify-start md:items-center gap-5  grid sm:grid-cols-3 grid-cols-2  max-[320px]:grid-cols-1 '> 
      {categoryData.map(movie => {
          const [movieTitle, year] = movie.name.split(/ \((\d{4})\)/);
          return (
          <div key={movie.id}>
            <div className='relative md:w-[180px] md:h-[230px] sm:w-[150px] sm:h-[200px] w-[150px] h-[200px] overflow-hidden transition-transform ease-out duration-500 delay-300 sm:hover:scale-[1.25] hover:z-10 rounded-md'>
            <Link to={`/main-list/${movie.id}`} className=' '>
             <img src={movie.poster} alt={movie.name} className='md:w-[180px] md:h-[230px] sm:w-[150px] sm:h-[200px] w-[150px] h-[200px] rounded-lg transition-transform duration-500 transform sm:hover:scale-[1.20]' />
            </Link>
              {/* <footer className='absolute max-sm:hidden inset-0 bg-black bg-opacity-60 opacity-0 hover:opacity-100 flex flex-col justify-end text-white p-4 transition-opacity duration-300'>
                <Link to={`/main-list/${movie.id}`} className='mb-2 bg-white text-black text-xs w-full h-9 font-semibold rounded-md duration-200 ease-linear transform hover:scale-[1.020] flex items-center justify-center gap-3'>
                  <FaPlay className='text-[10px]' /> Watch Now
                </Link>
                <p className="text-xs font-semibold flex-col flex flex-2 ">{movieTitle}</p>
                <div className='text-xs font-semibold'>
                  <span>{year}</span> <span> </span>
                  <span className="">{movie.genres}</span> <span>| </span>
                  <span>{movie.languages}</span>
                </div>
              </footer> */}
               <footer className=' max-sm:hidden absolute inset-0 bg-black bg-opacity-60 opacity-0 hover:opacity-100 flex flex-col justify-end text-white p-4 transition-opacity duration-300'>
                      <span className=' flex-1 text-green-400 font-bold text-[10px]'>{movie.languages}</span>
                        <Link to={`/main-list/${movie.id}`} className='mb-2 bg-white text-black text-xs w-full h-8 font-semibold rounded-md duration-200 ease-linear transform hover:scale-[1.020] flex items-center justify-center gap-3'>
                          <FaPlay className='text-[10px]' /> Watch Now
                        </Link>
                        <p className="uppercase text-xs font-bold ">{movieTitle} {year}</p>
                        <div className='text-xs font-semibold flex-col flex flex-2'>
                          {/* <span>{year}</span>  */}
                          <span className=" text-[#fffcc2] text-[10px]">{movie.genres}</span> 
                          {/* <span>{item.languages}</span> */}
                        </div>
                      </footer>
            </div>
          </div>
       );
    })}
    </div>
    </div>  
  );
};

export default HomeListCategory;
