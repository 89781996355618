import React, { useState } from 'react';
// import { API_URL } from '../../config/config';
// import axios from 'axios';
import { MdArrowOutward } from 'react-icons/md';
import { Link } from 'react-router-dom';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import Modal from '../Common/Modal';
import Loader from '../Common/Loader';

const Request = () => {
  const [link, setLink] = useState('');
  const [isValidLink, setIsValidLink] = useState(true);
  const axiosPrivate = useAxiosPrivate();
  const [modalOpen, setModalOpen] = useState(false);
  const [responseCode, setResponseCode] = useState(null);
  const [loading, setLoading] = useState(false);

  const verifyLink = (link) => {
    const imdbPattern = /^https?:\/\/www\.imdb\.com\/title\/tt\d{7,8}\/?.*$/;
    const imdbPattern2 = /^https?:\/\/m\.imdb\.com\/title\/tt\d{7,8}\/?.*$/;

    return imdbPattern.test(link) || imdbPattern2.test(link);
  };

  const handleLinkChange = (e) => {
    const newLink = e.target.value;
    setLink(newLink);
    setIsValidLink(verifyLink(newLink));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (link === '' || !isValidLink) {
      window.alert('Please enter a valid link');
      return;
    }

    try {
      setLoading(true);
      const response = await axiosPrivate.post(`/addRequestCine`, {
        request: link,
      });
      setLoading(false);
      setResponseCode(response.status);
      setModalOpen(true);

      if (response.status === 200) {
        setLink('');
        setIsValidLink(true);
      }
    } catch (error) {
      setLoading(false);
      console.error('Error sending request:', error);
      setResponseCode(error.response?.status || 'Unknown Error');
      setModalOpen(true);
    }
  };

  return (
    <div>
      {loading ? (
        <div className='h-screen flex justify-center items-center'>
          <div className=' w-48'>
            <Loader />
          </div>
        </div>
      ) : (
        <div>
          {/* <Link to={`/`} className=' absolute left-8 flex gap-2 opacity-50 items-center  sm:text-sm text-xs'>
     <span className=' font-bold'> ←  </span> <span>Go Back</span>
    </Link> */}
          <p className=' text-center mt-8 font-bold sm:text-2xl text-lg '>
            Request
          </p>
          {/* 1st container  */}
          <div className=' flex justify-center items-center flex-col mt-8 max-lg:m-4 '>
            <div className=' bg-[#1D1D1D]  lg:w-[500px] lg:h-[500px] w-full lg:px-28 px-4 py-6 rounded-lg flex justify-center items-center flex-col '>
              <p className=' flex flex-row  gap-2 items-center text-yellow-400 font-bold text-xl mb-4'>
                Request Feature{' '}
                <span>
                  {' '}
                  <img
                    src={`https://cdn3.emoji.gg/emojis/8385_purple_gem.png`}
                    alt=''
                    className=' w-5'
                  />{' '}
                </span>{' '}
              </p>
              <div className=' text-sm  font-semibold opacity-50'>
                <p className=' mb-2'>Request availability: 24x7, entire week</p>
                <p className='mb-2'> Request uploaded within 48 hours</p>
              </div>
              <div className=' mb-6 mt-6 '>
                {/* <button className=' px-2 py-2 rounded  bg-white bg-opacity-15 mb-5 font-semibold '>
                  Unlimited Request
                </button> */}
                <p className=' text-center font-semibold'>
                  Please Enter the IMDB link of the movies or webseries you wish
                  to add!
                </p>
              </div>
              <form
                onSubmit={handleSubmit}
                className=' flex items-center justify-between md:w-96  gap-2 bg-black rounded-full relative mb-4'>
                <input
                  className=' rounded-full bg-black border-none bg-none outline-none text-white text-base p-6 pl-6 pr-11 max-[320px]:pr-2 font-semibold'
                  type='text'
                  name=''
                  placeholder='Enter IMDB Link'
                  value={link}
                  onChange={handleLinkChange}
                />
                <button
                  className='text-white absolute right-2 md:w-12 md:h-12 w-10 h-10 rounded-full bg-gradient-to-r from-red-700 to-red-500 border-0 transition-all duration-300 ease-in-out searchButton hover:bg-gray-900 hover:shadow-lg hover:-translate-y-1 active:shadow-none active:translate-y-0'
                  href='#'>
                  <MdArrowOutward className='  items-center md:text-5xl text-[40px] ' />
                </button>
              </form>
              <Link
                to='https://www.youtube.com/shorts/oxTrD1dy2o0'
                className=' flex items-center justify-center font-light text-yellow-200 text-sm '>
                How to Get IMDB link ?
              </Link>
            </div>
          </div>
          {/* end */}
          {/* 2nd container  */}
          <div className=' bg-red-900 lg:absolute top-0 lg:mt-32 lg:w-[400px] max-lg:flex max-lg:m-4 rounded-lg p-2 lg:ml-36 xl:ml-28  ml-0 shadow-2xl shadow-black '>
            <div className=' px-10 py-8'>
              <p className=' text-center font-semibold text-xl mb-6'>
                Request Rules
              </p>
              <p className=' mb-4'>
                You can only request content which is not already available on
                cinematix!
              </p>
              <p className=' mb-4'>
                Only IMDB registered content are allowed in request{' '}
              </p>
              <p className=' mb-4'>
                Only request Movies/Series/Anime, don't request TV serials/shows.
              </p>
              <p>
                You can request missing Qualities of any movies except webseries
                because they are already in HD quality !
              </p>
            </div>
          </div>
          {modalOpen && (
            <Modal
              statusCode={responseCode}
              onClose={() => setModalOpen(false)}
            />
          )}

          {/* end */}
        </div>
      )}
    </div>
  );
};

export default Request;