import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AuthProvider } from './Components/context/AuthProvider';
// import { DataProvider } from "./Components/context/DataProvider";
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <GoogleOAuthProvider clientId="412491682963-7f2i1uptdrne011aulluvt1f4v1kvsg5.apps.googleusercontent.com">
    <BrowserRouter>
      <AuthProvider>     
          <App />
      </AuthProvider>
    </BrowserRouter>
  </GoogleOAuthProvider>

);
