import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaPlay } from "react-icons/fa";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { FaGooglePlay } from "react-icons/fa";
import Logo from '../Images/cinematixlogo.png';

const Hero = ({ image, title, genre, rating, id }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handleImageLoad = () => {
      setIsLoading(false);
    };

    const img = new Image();
    img.src = image;
    img.onload = handleImageLoad;
  }, [image]);

  return (
    <div className='relative -z-10'>
      <div className="relative">
        {isLoading ? (
          <div className='flex flex-col'>
            <Skeleton className='md:h-[700px] h-[250px]' baseColor='#3F3F3F' />
          </div>
        ) : (
          <img src={image} alt="main banner" className="w-full" />
        )}
        <div className="absolute inset-0 w-[50%] bg-gradient-to-r from-black to-transparent" />
        <div className="absolute bottom-0 w-full h-[25%] bg-gradient-to-t from-[#0C0404] to-transparent" />

        <div className="md:hidden fixed top-0 left-0 z-20 p-2 mt-2 ms-2">
          <a href="/">
            <img src={Logo} alt="Logo" className="w-8 h-8" />
          </a>
        </div>

        {/* Download App Banner */}
        <a
          href="https://play.google.com/store/apps/details?id=com.cinematix.mobiletalkies&hl=en_US"
          target="_blank"
          rel="noopener noreferrer"
          className="
    absolute top-4 right-4 
    bg-gradient-to-r from-[#1D2671] to-[#C33764] 
    text-white 
    py-2 px-4 rounded-lg shadow-lg 
    hover:scale-105 transform transition duration-300 ease-in-out 
    flex items-center z-20 
    md:py-3 md:px-6 md:rounded-xl md:text-base md:mr-2
  "
        >
          <FaGooglePlay className="mr-1 text-base md:text-lg md:mr-2" />
          <span className="font-semibold text-xs md:text-sm">Download for Android</span>
        </a>

      </div>

      <div className='pa lg:top-56 top-20 max-md:hidden'>
        <p className='lg:text-5xl md:text-3xl sm:text-2xl mb-6 font-bold uppercase'>
          {isLoading ? <Skeleton width={300} /> : title}
        </p>
        <div className='w-[550px] mb-4'></div>
        <span className='text-lg font-bold'>
          {isLoading ? <Skeleton width={100} /> : genre}
        </span>
        <br /><br />
        <span className='text-lg font-semibold'>
          {isLoading ? <Skeleton width={50} /> : rating}
        </span>
        <div className="mt-8">
          {isLoading ? (
            <Skeleton width={288} height={48} />
          ) : (
            <Link to={`/main-list/${id}`} className='bg-white bg-opacity-25 font-semibold text-xl w-72 h-12 rounded-md duration-200 ease-linear transform hover:scale-105 flex justify-center items-center'>
              Watch Now
            </Link>
          )}
        </div>
      </div>

      {/* for below md  */}
      <div className='bottom-10 left-1/2 transform -translate-x-1/2 flex justify-center items-center flex-col absolute w-full md:hidden'>
        {isLoading ? (
          <Skeleton circle={true} width={56} height={56} />
        ) : (
          <Link to={`/main-list/${id}`} className='w-14 h-14 rounded-full border-2 border-white flex items-center justify-center bg-white bg-opacity-15 mb-8 max-[320px]:mb-0'>
            <FaPlay className='opacity-80 text-lg flex items-center justify-center' />
          </Link>
        )}
        <p className='font-semibold text-xs opacity-70'>
          {isLoading ? <Skeleton width={30} /> : rating}
        </p>
        <p className='font-bold text-xl max-[320px]:text-lg text-center'>
          {isLoading ? <Skeleton width={150} /> : title}
        </p>
        <p className='font-semibold text-[10px] opacity-50'>
          {isLoading ? <Skeleton width={100} /> : genre}
        </p>
      </div>
    </div>
  );
};

export default Hero;
