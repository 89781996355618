import React from 'react'
import Lottie from 'lottie-react';
import LOADER from '../../assets/loader.json'

const Loader = () => {
  return (
    <div className=''>
      <Lottie animationData={LOADER}/>
    </div>
  )
}

export default Loader
